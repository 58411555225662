<template>
  <div>
    <div class="flix-bg-info flix-bg-overflow" >
      <a @click.prevent="setElement(value[1])" v-for="(value) in getComponents()" href="#" class="flix-btn flix-btn-default" :key="value[0]"><flixIcon :id="'plus'" /> {{ value[0] }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { form: Array },
  data () {
    return {
      components: {
        email: ['Emailadressfeld', {
          type: 'text',
          subtype: 'email',
          label: 'E-Mailadresse',
          columnName: '12',
          description: '',
          required: false,
          value: ''
        }],
        header: ['Überschrift', {
          type: 'header',
          subtype: 'h2',
          label: 'Überschrift',
          columnName: '12',
          description: '',
          required: false,
          value: ''
        }],
        paragraph: ['Erklärtext', {
          type: 'paragraph',
          subtype: 'p',
          label: '',
          columnName: '12',
          description: 'Erklärtext',
          required: false,
          value: ''
        }],
        text: ['Textfeld', {
          type: 'text',
          subtype: 'text',
          label: 'Bitte ausfüllen',
          columnName: '12',
          description: '',
          required: false,
          value: ''
        }],
        textarea: ['Textblock', {
          type: 'textarea',
          subtype: 'textarea',
          label: 'Bitte ausfüllen',
          columnName: '12',
          description: '',
          required: false,
          value: ''
        }],
        tel: ['Telefonnummernabfrage', {
          type: 'text',
          subtype: 'tel',
          label: 'Telefonnummer',
          columnName: '12',
          description: '',
          required: false,
          value: ''
        }],
        radio: ['Einzelauswahl', {
          type: 'radio',
          subtype: 'radio',
          label: 'Einzelauswahl',
          columnName: '12',
          description: '',
          required: false,
          value: '',
          values: [{ label: 'Option 1', value: 'Option 1' }, { label: 'Option 2', value: 'Option 2' }, { label: 'Option 3', value: 'Option 3' }]
        }],
        checkbox: ['Mehrfachauswahl', {
          type: 'checkbox',
          subtype: 'checkbox',
          label: 'Mehrfachauswahl',
          columnName: '12',
          description: '',
          required: false,
          value: '',
          values: [{ label: 'Option 1', value: 'Option 1' }, { label: 'Option 2', value: 'Option 2' }, { label: 'Option 3', value: 'Option 3' }]
        }],
        agb: ['AGB', {
          type: 'agb',
          subtype: 'agb',
          label: this.$t('message.acceptTerms'),
          columnName: '12',
          description: this.getTerms(),
          required: true,
          value: '',
          values: ['yes', 'no']
        }]
      }
    }
  },
  methods: {
    setElement (data) {
      data.id = new Date().getTime()
      this.form.splice(0, 0, data)
    },
    getTerms () {
      this.user = this.$store.getters.user
      if (typeof this.user.terms === 'undefined') {
        return ''
      }
      return decodeURIComponent(escape(this.user.terms))
    },
    getComponents () {
      var email = false
      var tel = false
      var components = JSON.parse(JSON.stringify(this.components))

      this.form.forEach(function (elem) {
        if (elem.type === 'text' && elem.subtype === 'email') {
          email = true
        }
        if (elem.type === 'text' && elem.subtype === 'tel') {
          tel = true
        }
      })
      if (tel) {
        delete components.tel
      }
      if (email) {
        delete components.email
      }
      return components
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-bg-overflow
    overflow-x: auto
    overflow-y: hidden
    white-space: nowrap
    width: 100%
    padding: 20px
    box-sizing: border-box
    display: inline-block

    .flix-btn
      margin-right: 10px
</style>
